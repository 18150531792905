<template>
  <div class="authentication-bg min-vh-100">
    <div class="bg-overlay bg-black"></div>

    <div class="container">
      <div class="d-flex flex-column min-vh-100 px-3">
        <div class="row justify-content-center my-auto">
          <div class="col-lg-6 col-xl-6">
            <div class="text-center py-5">

              <!-- // Logo -->
              <div class="mb-2 mb-md-4">
                <div class="d-block auth-logo">
                  <img src="@/assets/images/spocha-text-logo.png" alt=""
                       height="56" class="auth-logo-dark" />
                  <img src="@/assets/images/spocha-text-logo.png" alt=""
                       height="56" class="auth-logo-light" />
                </div>
              </div>

              <div class="mb-4">
                <h5 class="text-white">{{ $t('account.memberJoin') }}</h5>
              </div>

              <!-- Form Start -->
              <form action="#">

                <!-- // Wizard Navi -->
                <ul class="wizard-nav mb-4">
                  <li class="wizard-list-item">
                    <div class="list-item">
                      <div class="step-icon" data-bs-toggle="tooltip" data-bs-placement="top">
                        <i class="uil uil-list-ul"></i>
                      </div>
                    </div>
                  </li>
                  <li class="wizard-list-item">
                    <div class="list-item">
                      <div class="step-icon" data-bs-toggle="tooltip" data-bs-placement="top">
                        <i class="uil uil-clipboard-notes"></i>
                      </div>
                    </div>
                  </li>
                </ul>

                <!-- // Wizard Tab 1 -->
                <div class="wizard-tab">
                  <div class="text-center mb-4">
                    <h5 class="text-white-50">{{ $t('account.useTermsAgree') }}</h5>
                  </div>
                  <div>
                    <div class="row mb-4">
                      <div class="col-12">
                        <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" v-model="allCheck" value="all" />
                          <label class="form-check-label text-white" for="sing-up-all-check">{{ $t('account.allAgree') }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-12">
                        <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" id="sing-up-use-check" v-model="selectList" value="use" />
                          <label class="form-check-label text-white" for="sing-up-use-check">
                            <span>{{ $t('account.useTerms') }}</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                        </div>
                        <div>
                          <div class="form-control" v-html="contentsTerms.use" style="height: 80px; overflow-y: auto;" />
<!--                          <b-form-textarea rows="3" v-model="contentsTerms.use" no-resize disabled />-->
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-12">
                        <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" id="sing-up-essential-check" v-model="selectList" value="essential" />
                          <label class="form-check-label text-white" for="sing-up-essential-check">
                            <span>{{ $t('account.usePersonalInfo') }}</span>
                            <span class="ms-1">({{ $t('common.essential') }})</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                        </div>
                        <div>
                          <div class="form-control" v-html="contentsTerms.essential" style="height: 80px; overflow-y: auto;" />
<!--                          <b-form-textarea rows="3" v-model="contentsTerms.essential" no-resize disabled />-->
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-check mb-2">
                          <input class="form-check-input" type="checkbox" id="sing-up-choice-check" v-model="selectList" value="choice" />
                          <label class="form-check-label text-white" for="sing-up-choice-check">
                            <span>{{ $t('account.usePersonalInfo') }}</span>
                            <span class="ms-1">({{ $t('common.choice') }})</span>
                          </label>
                        </div>
                        <div>
                          <div class="form-control" v-html="contentsTerms.choice" style="height: 80px; overflow-y: auto;" />
<!--                          <b-form-textarea rows="3" v-model="contentsTerms.choice" no-resize disabled />-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- // Wizard Tab 2 -->
                <div class="wizard-tab">
                  <div class="text-center mb-3">
                    <h5 class="text-white-50">{{ $t('account.enterInfo') }}</h5>
                  </div>
                  <div>
                    <h5 class="text-start text-primary ms-1">{{ $t('account.basicInfo') }}</h5>
                    <div class="row mb-3">
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <input type="text"
                                 v-model="form.userId"
                                 class="form-control"
                                 id="sign-up-input-user-id"
                                 placeholder="Enter User ID"
                                 :class="{ 'is-invalid': submitted && $v.form.userId.$error }" />
                          <label for="sign-up-input-user-id">
                            <span>{{ $t('account.id') }}</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-email-outline"></i>
                          </div>
                          <div v-if="submitted && $v.form.userId.$error" class="invalid-feedback">
                            <span v-if="!$v.form.userId.required">{{ $t('validation.emailIdIsRequired') }}</span>
                            <span v-if="!$v.form.userId.email">{{ $t('validation.enterValidEmail') }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <b-form-select class="form-select"
                                         id="sign-up-select-user-role"
                                         v-model="form.role"
                                         :options="roleOptions" @change="changeRole" />
                          <label for="sign-up-select-user-role">{{ $t('account.role') }}</label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-sitemap"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <input type="password"
                                 v-model="form.userPwd"
                                 class="form-control"
                                 id="sign-up-input-user-pwd"
                                 placeholder="Enter User Password"
                                 :class="{ 'is-invalid': submitted && $v.form.userPwd.$error }" />
                          <label for="sign-up-input-user-pwd">
                            <span>{{ $t('common.password') }}</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-lock-outline"></i>
                          </div>
                          <div v-if="submitted && $v.form.userPwd.$error" class="invalid-feedback">
                            <span v-if="!$v.form.userPwd.required">{{ $t('validation.pwdIsRequired') }}</span>
                            <span v-else-if="!$v.form.userPwd.validPassword">{{ $t('validation.pwdValidationText') }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <input type="password"
                                 v-model="form.userPwdCheck"
                                 class="form-control"
                                 id="sign-up-input-user-pwd-check"
                                 placeholder="Enter User Password"
                                 :class="{ 'is-invalid': submitted && $v.form.userPwdCheck.$error }" />
                          <label for="sign-up-input-user-pwd-check">
                            <span>{{ $t('common.pwdCheck') }}</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-lock-outline"></i>
                          </div>
                          <div v-if="submitted && $v.form.userPwdCheck.$error" class="invalid-feedback">
                            <span v-if="!$v.form.userPwdCheck.required">{{ $t('validation.pwdIsRequired') }}</span>
                            <span v-else-if="!$v.form.userPwdCheck.sameAs">{{ $t('validation.pwdCheckPlease') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-4">
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <input type="text"
                                 v-model="form.name"
                                 class="form-control"
                                 id="sign-up-input-name"
                                 placeholder="Enter User Name"
                                 :class="{ 'is-invalid': submitted && $v.form.name.$error }" />
                          <label for="sign-up-input-name">
                            <span>{{ $t('account.name') }}</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-account-outline"></i>
                          </div>
                          <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.name.required">{{ $t('validation.nameIsRequired') }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <input type="text"
                                 v-model="form.tel"
                                 class="form-control"
                                 id="sign-up-input-tel"
                                 placeholder="Enter User Phone Number"
                                 :class="{ 'is-invalid': submitted && $v.form.tel.$error }" />
                          <label for="sign-up-input-tel">
                            <span>{{ $t('account.tel') }}</span>
                            <i class="uil uil-asterisk text-danger ms-1"></i>
                          </label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-phone-outline"></i>
                          </div>
                          <div v-if="submitted && $v.form.tel.$error" class="invalid-feedback">
                            <span v-if="!$v.form.tel.required">{{ $t('validation.telIsRequired') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 class="text-start text-primary ms-1">{{ $t('account.agencyInfo') }}</h5>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <b-form-select class="form-select"
                                         id="sign-up-select-hq"
                                         v-model="hqId"
                                         :options="hqOptions" :disabled="form.role === 'ADMIN'"
                                         @change="setCpOptions"/>
                          <label for="sign-up-select-hq">{{ $t('common.headquarters') }}</label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-office-building-outline"></i>
                          </div>
                        </div>
                      </div>
                      <div v-if="form.role === 'CP'" class="col-lg-6">
                        <div class="form-floating form-floating-custom">
                          <b-form-select class="form-select"
                                         id="sign-up-select-charge-place"
                                         v-model="cpId"
                                         :options="cpOptions" :disabled="form.role !== 'CP'" />
                          <label for="sign-up-select-charge-place">{{ $t('common.cp') }}</label>
                          <div class="form-floating-icon">
                            <i class="mdi mdi-ev-station"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- // Prev and Next Button -->
                <div class="d-flex align-items-start gap-3 mt-4">
                  <button type="button" class="btn btn-primary w-sm" id="prevBtn"
                          @click="nextPrev(-1)">
                    {{ $t('btn.prev') }}
                  </button>
                  <button type="button" class="btn btn-primary w-sm ms-auto" id="nextBtn"
                          @click="nextPrev(1)">
                    {{ $t('btn.next') }}
                  </button>
                </div>

                <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{ authError }}</b-alert>

                <div class="mt-5 mb-5 text-center">
                  <p>
                    <span class="me-3 text-white-50">{{ $t('account.alreadyAccount') }}</span>
                    <router-link to="/login" class="fw-medium text-decoration-underline">{{ $t('account.signIn') }}</router-link>
                  </p>
                </div>

              </form>
              <!-- Form End -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/auth-api';
import { loadingMethods } from '@/state/helpers';
import i18n from '@/i18n';
import Swal from 'sweetalert2';
import { required, email, sameAs, helpers } from 'vuelidate/lib/validators';

export default {
  computed: {
    allCheck: {
      get: function() {
        return this.checkList.length === this.selectList.length;
      },
      set: function(e) {
        this.selectList = e ? this.checkList : [];
      }
    }
  },
  data() {
    return {
      currentTab: 0,
      submitted: false,
      selectList: [],
      checkList: [ 'use', 'essential', 'choice' ],
      contentsTerms: {
        use: null,
        essential: null,
        choice: null
      },
      form: {
        userId: null,
        role: null,
        userPwd: null,
        userPwdCheck: null,
        name: null,
        tel: null
      },
      hqId: null,
      cpId: null,
      roleOptions: [],
      hqCpList: [],
      hqOptions: [],
      cpOptions: [],
      isAuthError: false,
      authError: null
    };
  },
  validations: {
    form: {
      userId: { required, email },
      userPwd: {
        required,
        validPassword: helpers.withParams(
          {message: ''},
          value => /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,12}$/.test(value || '')
        ),
      },
      userPwdCheck: { required, sameAsPassword: sameAs('userPwd') },
      name: { required },
      tel: { required }
    },
  },
  mounted() {
    const vm = this;
    vm.showTab(vm.currentTab);

    vm.getTerms();
    vm.getRoleOptions();
  },
  methods: {
    ...loadingMethods,
    getTerms() {
      const vm = this;

      const _url = '/onmapi/view/TermsPolicy/list';

      http.post(_url, {
        page: 1,
        limit: 100,
        searchConditions: [{ 'searchKey': 'appCsmsType', 'searchValue': 'CSMS' }],
        sortCondition: { sortDirection: 'DESC' }
      }).then(res => {
        res.data.items.forEach(item => {
          if (item.id === 10000000000769) {
            vm.contentsTerms.use = item.contents;
          } else if (item.id === 10000000000770) {
            vm.contentsTerms.essential = item.contents;
          } else if (item.id === 10000000000771) {
            vm.contentsTerms.choice = item.contents;
          }
        });
      }).catch(err => {
        console.error('Terms List Error :: ', err)
      });
    },
    changeRole() {
      const vm = this;

      if (vm.form.role === 'ADMIN') {
        vm.hqId = null;
        vm.hqOptions = [];

        vm.cpId = null;
        vm.cpOptions = [];
      } else if (vm.form.role === 'HQ') {
        vm.hqId = null;
        vm.hqOptions = [];

        vm.cpId = null;
        vm.cpOptions = [];

        vm.getHqCpOptions();
      } else if (vm.form.role === 'CP') {
        vm.hqId = null;
        vm.hqOptions = [];

        vm.cpId = null;
        vm.cpOptions = [{ text: i18n.t('alert.selectHq'), value: null }];

        vm.getHqCpOptions();
      }
    },
    getRoleOptions() {
      const vm = this;

      vm.roleOptions = [];
      vm.roleOptions = Object.entries(this.$_app.code.role).map(([key, value]) => ({ text: value, value: key }));
      vm.form.role = vm.roleOptions[0].value;
    },
    getHqCpOptions() {
      const vm = this;
      const _url = '/public/api/HqBusiness/list';

      http.get(_url, {}).then(res => {
        vm.hqCpList = res.data.items;
        vm.hqOptions = [];

        vm.hqCpList.forEach(item => {
          vm.hqOptions.push({text: item.hqName, value: item.id});
        });
        /*vm.cpOptions = [{ text: i18n.t('account.selectHq'), value: null }];*/
      }).catch(err => {
        console.error('Headquarters List Data Error :: ', err)
      });
    },
    setCpOptions() {
      const vm = this;

      vm.cpId = null;

      if (vm.form.role === 'CP') {
        if (!this.$_utils.isEmpty(vm.hqId)) {
          const hqInfo = vm.hqCpList.find(ele => ele.id === vm.hqId);

          if (hqInfo.chargePlaces.length > 0) {
            vm.cpOptions = [];

            hqInfo.chargePlaces.forEach(item => {
              vm.cpOptions.push({text: item.placeName, value: item.placeId});
            });
          } else {
            vm.cpOptions = [{ text: i18n.t('alert.emptyCp'), value: null }];
          }
        } else {
          vm.cpOptions = [];
        }
      } else {
        vm.cpOptions = [];
      }
    },
    showTab(n) {
      const vm = this;

      let x = document.getElementsByClassName('wizard-tab');
      x[n].style.display = 'block';

      if (n === 0) {
        document.getElementById('prevBtn').style.display = 'none';
      } else {
        document.getElementById('prevBtn').style.display = 'inline';
      }

      if (n === x.length - 1) {
        document.getElementById('nextBtn').innerHTML = `${i18n.t('btn.register')}`;
      } else {
        document.getElementById('nextBtn').innerHTML = `${i18n.t('btn.next')}`;
      }

      vm.fixStepIndicator(n);
    },
    fixStepIndicator(n) {
      let i, x = document.getElementsByClassName('list-item');

      for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(' active', '');
      }

      x[n].className += ' active';
    },
    nextPrev(n) {
      const vm = this;

      // Terms Agree Check
      if(!(vm.selectList.includes('use') && vm.selectList.includes('essential'))) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.termsAgreeWarning'),
          confirmButtonText: i18n.t('btn.close')
        });

        return false;
      }

      if (n > 0 && document.getElementById('nextBtn').innerHTML === `${i18n.t('btn.register')}`) {
        vm.tryToSignUp();
      } else {
        let x = document.getElementsByClassName('wizard-tab');

        x[vm.currentTab].style.display = 'none';
        vm.currentTab = vm.currentTab + n;

        if (vm.currentTab >= x.length) {
          vm.currentTab = vm.currentTab - n;
          x[vm.currentTab].style.display = 'block';
        }

        vm.showTab(vm.currentTab);
      }
    },
    tryToSignUp() {
      const vm = this;

      if (vm.form.role === 'HQ' && this.$_utils.isEmpty(vm.hqId)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.selectHq'),
          confirmButtonText: i18n.t('btn.close')
        });

        return false;
      } else if (vm.form.role === 'CP' && this.$_utils.isEmpty(vm.hqId) && this.$_utils.isEmpty(vm.cpId)) {
        Swal.fire({
          icon: 'warning',
          title: i18n.t('alert.selectHqCp'),
          confirmButtonText: i18n.t('btn.close')
        });

        return false;
      }

      vm.submitted = true;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        // TODO: 이용 약관 등록 (Backend 구현 필요)
        vm.showOverlay();

        let _form = {
          role: vm.form.role,
          email: vm.form.userId,
          password: vm.form.userPwd,
          name: vm.form.name,
          mobile: vm.form.tel,
          hqId: vm.hqId,
          cpId: vm.cpId
        };

        this.$_app.signUp(_form).then(res => {
          vm.hideOverlay();
          this.$router.push({ path: '/verification' });
        }).catch(err => {
          vm.hideOverlay();

          if (err.response.data.message === 'Email address already in use.') {
            vm.isAuthError = true;
            vm.authError = i18n.t('account.errUsedEmail');
          }
        });
      }
    }
  }
}
</script>
